import React from 'react';
import SEO from '../components/seo/SEO';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


export default function GreaterLiveability () { 

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "greater-liveability/bkg--greater-liveability.png"}) {
        childImageSharp {
          fixed(width: 1920, height: 1080, quality: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }  
  `)

  return (
    <>
      <SEO title='Greater Liveability' />
      <Img  fixed={ data.file.childImageSharp.fixed } alt='Greater Liveability' />
    </>
  );

}
